import Auth0Lock from 'auth0-lock';

export default new Auth0Lock(
  process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
  process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  {
    allowSignUp: false,
    rememberLastLogin: false,
    container: 'login',
    theme: {
      primaryColor: '#1E4962',
      foregroundColor: '#FFFFFF',
    } as any,
    auth: {
      audience: process.env.REACT_APP_AUTH0_API_AUDIENCE ?? '',
      params: {
        scope: 'openid profile email',
      },
      redirectUrl: `${window.location.origin}/entry`,
      responseType: 'token',
    },
  },
);
