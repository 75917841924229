import React from 'react';
import { Input, InputGroup } from 'reactstrap';

import { Touchpoint, TouchpointType } from '../../../../types';
// import ContentBuilderInput from './ContentBuilderInput';

const EmailInput = ({
  disabled = false,
  formik,
  onMouseEnter,
  onMouseLeave,
  captureInput,
}: {
  disabled?: boolean,
  formik: {
    values: Touchpoint,
    errors: any,
    touched: any,
    handleBlur: (e: React.FocusEvent<any, Element>) => void,
  },
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  captureInput: (target: string, value: string) => void;
}) => {
  const {
    values: { type, subject, message },
    errors,
    touched,
    handleBlur,
  } = formik;

  return (
    <>
      <InputGroup>
        <Input
          className="campaign-touchpoint-message-subject"
          type="text"
          placeholder="subject"
          name="subject"
          disabled={disabled}
          onChange={(e) => captureInput('subject', e.target.value)}
          onBlur={handleBlur}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          value={subject}
          invalid={touched.subject && !!errors.subject}
        />
      </InputGroup>
      {type === TouchpointType.Email ? (
        <InputGroup className="message-container">
          <Input
            className="campaign-touchpoint-message"
            type="textarea"
            name="message"
            disabled={disabled}
            onChange={(e) => captureInput('message', e.target.value)}
            onBlur={handleBlur}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            value={message}
            invalid={touched.message && !!errors.message}
          />
        </InputGroup>
      ) : (
        <div>Implement content builder</div>
      )}

      {/* <ContentBuilderInput
          id={`touchpoint-${index}`}
          message={message as string}
          hasData={!!message}
          isActive={usingContentBuilder}
          setTarget={() => setContentBuilderTarget(index)}
        /> */}
    </>
  );
};

export default EmailInput;
