import {
  action, Action, thunk, Thunk,
} from 'easy-peasy';
import { toast } from 'react-toastify';
import { StoreModel } from '.';

import api from '../../api';
import {
  ToastType,
  ToastNotification,
  UserMetadata,
  NotifyMetadata,
} from '../../types';

export interface MetaModel {
  notify: Action<MetaModel, ToastNotification>;
  processUserNotifications: Thunk<MetaModel, UserMetadata>;
  hadLeadNotification: Thunk<MetaModel, NotifyMetadata>;
  hadProspectNotification: Thunk<MetaModel, NotifyMetadata>;
  nearMessageLimitNotification: Thunk<MetaModel>;
}

// app meta data or methods
const meta = (): MetaModel => ({
  notify: action((state, notification) => {
    const toastSettings = {
      position: 'top-right',
      autoClose: notification.stay ? false : 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    };

    switch (notification.type) {
      case ToastType.warning:
        toast.warn(notification.message, toastSettings as any);
        break;
      case ToastType.info:
        toast.info(notification.message, toastSettings as any);
        break;
      case ToastType.error:
        toast.error(notification.message, toastSettings as any);
        break;
      case ToastType.success:
        toast.success(notification.message, toastSettings as any);
        break;
      default:
        toast(notification.message, toastSettings as any);
        break;
    }
  }),
  processUserNotifications: thunk(
    async ({
      hadProspectNotification,
      hadLeadNotification,
      nearMessageLimitNotification,
    }, metadata) => {
      if (metadata?.hadLead?.notify) hadLeadNotification(metadata.hadLead!);
      if (metadata?.hadProspect?.notify) hadProspectNotification(metadata.hadProspect!);
      if (metadata?.nearMessageLimit?.notify) nearMessageLimitNotification();
    },
  ),
  hadLeadNotification: thunk(
    async ({ notify }, data) => {
      notify({
        message: 'You got your first lead!!! 🎉',
        type: ToastType.success,
      });

      await api.users.patchProfileMeta({ hadLead: { ...data, notify: false } });
    },
  ),
  hadProspectNotification: thunk(
    async ({ notify }, data) => {
      notify({
        message: 'You got your first prospect!!! 🎉',
        type: ToastType.success,
      });

      await api.users.patchProfileMeta({ hadProspect: { ...data, notify: false } });
    },
  ),
  nearMessageLimitNotification: thunk(
    async ({ notify }) => {
      notify({
        message: 'You\'re near the message limit',
        type: ToastType.warning,
        stay: true,
      });

      await api.users.patchProfileMeta({ nearMessageLimit: { notify: false } });
    },
  ),
});

export default meta;
