import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Spinner,
  Col,
  CardText,
  Button,
} from 'reactstrap';
import moment from 'moment';

import TopLoader from '../components/TopLoader';
import ConfirmationModal from '../components/Modals/ConfirmationModal';

import { PAYMENTS_REDIRECT, TOP_LOAD_RATE, TOP_LOAD_START } from '../../constants';

import { useStoreActions, useStoreState } from '../store';

const Subscription = () => {
  const ref = useRef(null);
  const navigate = useNavigate();

  const [confirmCancel, setConfirmCancel] = useState(false);

  const profile = useStoreState((state) => state.profile.user);

  const subscription = useStoreState((state) => state.subscription.subscription);

  const cancelState = useStoreState((state) => state.subscription.cancelState);
  const subscriptionState = useStoreState((state) => state.subscription.subscriptionState);

  const cancelSubscription = useStoreActions((actions) => actions.subscription.cancelSubscription);

  useEffect(() => {
    if (subscriptionState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (subscriptionState.success || subscriptionState.failure) (ref.current as any).complete();
  }, [subscriptionState]);

  useEffect(() => {
    if (cancelState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (cancelState.success || cancelState.failure) (ref.current as any).complete();
  }, [cancelState]);

  return (
    <div className="my-subscription mt-5">
      <TopLoader reference={ref} />
      <Row>
        <Col xs="4" />
        <Col xs="4">
          {!subscription
            ? <div>No Subscription</div>
            : (
              <Card>
                <CardHeader className="d-flex">
                  <div className="font-weight-bold flex-grow-1">
                    Subscription Details
                  </div>
                  <div>
                    <Button
                      color="link"
                      onClick={() => setConfirmCancel(true)}
                    >
                      Cancel Subscription
                    </Button>
                    <Button
                      color="link"
                      onClick={() => navigate(PAYMENTS_REDIRECT)}
                    >
                      Upgrade
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="d-flex">
                    <CardTitle className="font-weight-bold mr-2">
                      Active:
                    </CardTitle>
                    <CardText>{subscription.active ? 'Yes' : 'No'}</CardText>
                  </div>
                  <div className="d-flex">
                    <CardTitle className="font-weight-bold mr-2">
                      Plan Name:
                    </CardTitle>
                    <CardText>{subscription.trial ? 'Free Trial' : subscription.name}</CardText>
                  </div>
                  <div className="d-flex">
                    <CardTitle className="font-weight-bold mr-2">
                      Renewal Date:
                    </CardTitle>
                    <CardText>{moment(subscription.renewAt).format('LL')}</CardText>
                  </div>
                  <div className="d-flex">
                    <CardTitle className="font-weight-bold mr-2">
                      Member Since:
                    </CardTitle>
                    <CardText>{moment(subscription.subscribedAt).format('LL')}</CardText>
                  </div>
                </CardBody>
              </Card>
            )}
        </Col>
      </Row>
      <ConfirmationModal
        isOpen={confirmCancel}
        title="Are you sure you want to cancel your subscription?"
        onCancel={() => setConfirmCancel(false)}
        onConfirm={() => {
          cancelSubscription(profile?._id!);
          setConfirmCancel(false);
        }}
      />
    </div>
  );
};

export default Subscription;
